import { Modal, Button, Spinner } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { Link } from "react-router-dom";
import "./BillingModal.scss";
import { amp, ampProps, ampSet } from "../../utils/amplitude";
import { v4 as uuidv4 } from "uuid";

function MyVerticallyCenteredModal(props) {
  const [src, setSrc] = useState("");
  const [error, setError] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [failRedirect, setFailRedirect] = useState(false);
  const inputEl = useRef(null);
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    if (error) {
      setFailRedirect(true);
    }
  }, [error]);
  const listener = async (msg) => {
    console.log(msg);
    if (
      msg?.data?.response?.order?.status === "approved" ||
      msg?.data?.response?.order?.status === "void_ok"
    ) {
      //    await trackPurchase({...props, price:localStorage.getItem('hello')})
      props.bill();
      props.setBillPrice({ price: props.price });
      props.setCardToken({
        token:
          msg.data.response?.transactions[
            Object.keys(msg.data?.response?.transactions)[0]
          ]?.card?.card_token?.token ||
          msg.data?.response?.transactions[
            Object.keys(msg.data?.response?.transactions)[1]
          ]?.card?.card_token?.token,
      });
      ampSet("payment_type", "CARD");
      setRedirect(true);
    }
    if (
      msg.data.type === "orderStatus" &&
      (msg.data.response.order.status === "declined" ||
        msg.data.response.order.status === "auth_failed")
    ) {
      setError(msg.data.response.error.recommended_message_for_user);
    }
  };
  useEffect(() => {
    window.addEventListener("message", listener);
  }, []);
  useEffect(() => {
    if (props.show === false) {
      setCounter(0);
    }
    if (props.show === true) {
      amp("checkout_shown");
      fetch(
        `${
          process.env.REACT_APP_TEST_DOMEN === window.location.origin
            ? process.env.REACT_APP_TEST_SERVER
            : process.env.REACT_APP_LIVE_SERVER
        }/api/paypal/${
          process.env.REACT_APP_TEST_DOMEN === window.location.origin
            ? "test"
            : "live"
        }${props.product_id ? "/subscription" : ""}${
          props.product_id === "lower" ? "/lower" : ""
        }`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            auth:
              process.env.REACT_APP_TEST_DOMEN === window.location.origin
                ? process.env.REACT_APP_PAYPAL_TEST_MERCHANT
                : process.env.REACT_APP_PAYPAL_LIVE_MERCHANT,
          },
          body: JSON.stringify({
            amount: props.price,
            email: props.state.user.email || "emailnotentered@gmail.com",
            fbp: props.state.user.fbp,
            fbc: props.state.user.fbc,
            external_id: window.id,
            userAgent: navigator.userAgent,
            ...(props.product_id ? { product_id: props.product_id } : {}),
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          const script = document.createElement("script");
          script.src = res.url;
          inputEl?.current?.addEventListener(
            "order-processed",
            async function (e) {
              console.log("order-processed", e);
              console.log("order-processed", e.detail.data.order.status);
              if (
                e?.detail?.data?.order?.status === "approved" ||
                e?.detail?.data?.order?.status === "void_ok"
              ) {
                // await trackPurchase({...props, price:localStorage.getItem('hello')})
                props.bill();
                props.setBillPrice({ price: props.price });
                props.setPayPalToken({ token: e.detail.data.order.token });
                ampSet("payment_type", "PAYPAL");
                setRedirect(true);
              } else {
                setError(e.data.response.error.recommended_message_for_user);
              }
            },
            false
          );
          document.body.appendChild(script);
          setCounter(counter + 1);
        });
      fetch(
        `${
          process.env.REACT_APP_TEST_DOMEN === window.location.origin
            ? process.env.REACT_APP_TEST_SERVER
            : process.env.REACT_APP_LIVE_SERVER
        }/api/card/${
          process.env.REACT_APP_TEST_DOMEN === window.location.origin
            ? "test"
            : "live"
        }${props.product_id ? "/subscription" : ""}${
          props.product_id === "lower" ? "/lower" : ""
        }`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            auth:
              process.env.REACT_APP_TEST_DOMEN === window.location.origin
                ? process.env.REACT_APP_CARD_TEST_MERCHANT
                : props.product_id
                ? process.env.REACT_APP_CARD_SUBSCRIPTION_LIVE_MERCHANT
                : process.env.REACT_APP_CARD_LIVE_MERCHANT,
          },
          body: JSON.stringify({
            amount: props.price,
            email: props.state.user.email || "emailnotentered@gmail.com",
            fbp: props.state.user.fbp,
            fbc: props.state.user.fbc,
            userAgent: navigator.userAgent,
            external_id: window.id,
            ...(props.product_id ? { product_id: props.product_id } : {}),
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setSrc(res.url);
        });
    }
  }, [props.show]);

  if (redirect)
    return (
      <Redirect
        push
        to={{
          pathname: "/register",
          state: {
            successRedirect: props.successRedirect
              ? props.successRedirect
              : "/upsale",
          },
        }}
      />
    );
  if (failRedirect)
    return (
      <Redirect
        push
        to={{
          pathname: "/fail",
          state: { redirectUrl: props.location.pathname, error: error },
        }}
      />
    );
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ flexDirection: "row-reverse", borderBottom: 0 }}>
        {props.link ? (
          <Link push to={props.link}>
            <img
              className="header-icon"
              src="img/Union3.png"
              style={{ height: 16, width: 16 }}
              alt="img"
              onClick={() => props.onHide()}
            />
          </Link>
        ) : (
          <img
            className="header-icon"
            src="img/Union3.png"
            style={{ height: 16, width: 16 }}
            alt="img"
            onClick={() => {
              props.onHide();
            }}
          />
        )}
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="personality__modal">
          {props.dropped ? (
            <>
              <div
                className="personality__modal__charge"
                style={{ marginBottom: 10, paddingBottom: 8 }}
              >
                <span className="modal-checkout">Price per day</span>
                <span className="modal-price">
                  ${(props.price / 100 / 28).toFixed(2)}
                </span>
              </div>
              <div className="personality__modal__charge2">
                <span className="modal-checkout">Total:</span>
                <span className="modal-price">
                  ${(props.price / 100).toFixed(2)}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="personality__modal__charge">
                <span className="modal-checkout">Checkout</span>
                <span className="modal-price">
                  Total : <b>${props.price / 100}</b>{" "}
                </span>
              </div>
            </>
          )}

          <div
            className="personality__modal__buttons"
            style={counter === 1 ? { zIndex: 1 } : { zIndex: 0 }}
          >
            <>
              <div
                className="modal__button"
                ref={inputEl}
                id="paypal-button"
                style={{ border: "none", background: "white" }}
              ></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 12,
                  marginBottom: 12,
                }}
              >
                <div className="line"></div>
                <p className="modal__button__text">or</p>
                <div className="line"></div>
              </div>
            </>
            <iframe
              src={src}
              width="100%"
              height="400px"
              frameBorder="0"
            ></iframe>
            <p className="secured-payment">Your payment is secured</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default connect(
  (state) => ({ state }),
  (dispatch) => ({
    bill: () => {
      dispatch({ type: "SET_BILL" });
    },
    setBillPrice: (payload) => {
      dispatch({ type: "SET_PAID_PRICE", payload });
    },
    setCardToken: (payload) => {
      dispatch({ type: "SET_CARD_TOKEN", payload });
    },
    setPayPalToken: (payload) => {
      dispatch({ type: "SET_PAYPAL_TOKEN", payload });
    },
  })
)(withRouter(MyVerticallyCenteredModal));
// "https://my-fantasy2.herokuapp.com/api/card/live?email=test@gmail.com&amount=50"
