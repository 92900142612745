import React, { useState, useRef } from "react";
import "./slider.css";
import { useSwipeable } from "react-swipeable";

export default function Slider() {
  const myRef = useRef();

  const [active, setActive] = useState(0);
  const images = [
    "/img/personality/customers1.png",
    "/img/personality/customers2.png",
    "/img/personality/customers3.png",
    "/img/personality/customers4.png",
  ];
  const nextSlide = () => {
    console.log(active);
    if (active + 1 >= images.length) {
      setActive(0);
    } else {
      setActive(active + 1);
    }
  };
  const prevSlide = () => {
    if (active - 1 <= 0) {
      setActive(images.length - 1);
    } else {
      setActive(active - 1);
    }
  };
  const refPassthrough = (el) => {
    // call useSwipeable ref prop with el
    handlers.ref(el);

    // set myRef el so you can access it yourself
    myRef.current = el;
  };
  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="fading-slider">
      {/* <div className="dots-panel">
                {images.map((elem, index) => {
                    return <div className={(index === active)?"dot dot-active":"dot dot-unactive"}></div>
                })}
            </div> */}
      <div
        className="fading-slides"
        {...handlers}
        ref={refPassthrough}
        style={{ touchAction: "pan-y" }}
      >
        {images.map((elem, index) => {
          return (
            <div
              key={index}
              className={
                active === index
                  ? "fading-slider__container fading-slider__container-active"
                  : "fading-slider__container fading-slider__container-unactive"
              }
            >
              <img src={elem} className="fading-slider__img" alt="img" />
            </div>
          );
        })}
      </div>
      <div className="fading-slider-control">
        <div
          className="arrow-button"
          onClick={() => prevSlide()}
          style={{ boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.08)" }}
        >
          <img
            src="/img/personality/left.png"
            style={{ height: 24, width: 24 }}
            alt="img"
          />
        </div>
        <p>
          {active + 1} &nbsp; - &nbsp; {images.length}
        </p>
        <div
          className="arrow-button"
          onClick={() => nextSlide()}
          style={{ boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.08)" }}
        >
          <img
            src="/img/personality/right.png"
            style={{ height: 24, width: 24 }}
            alt="img"
          />
        </div>
      </div>
    </div>
  );
}
