import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
function MyVerticallyCenteredModal(props) {
  console.log(props);
  const [checkbox, setCheckbox] = useState(false);
  const openInNewWindow = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };
  const handleCheckbox = () => {
    setCheckbox(!checkbox);
  };
  useEffect(() => {
    console.log(checkbox);
  }, [checkbox]);
  const closeModal = () => {
    setCheckbox(false);
    props.onHide();
  };
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExit={closeModal}
    >
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px 15px 0px 0px",
          borderBottom: "none",
        }}
      >
        <img
          src="/img/home/close.png"
          style={{ height: 30, width: 30 }}
          onClick={closeModal}
          alt="img"
        />
      </Modal.Header>
      <Modal.Body
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 19,
          paddingBottom: 15,
        }}
      >
        <input
          type="checkbox"
          style={{ height: 23, width: 23, borderRadius: 5, marginTop: 5 }}
          onChange={handleCheckbox}
          value={checkbox}
        />

        <p
          style={{
            marginLeft: 16,
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 16,
            lineHeight: "130.2%",
            color: "#091E3F",
            marginBottom: 0,
            width: "80%",
          }}
        >
          I agree to the{" "}
          <span
            onClick={() => openInNewWindow("/terms-of-use")}
            style={{ textDecoration: "underline" }}
          >
            Terms of Service
          </span>{" "}
          and{" "}
          <span
            onClick={() => openInNewWindow("/privacy-policy")}
            style={{ textDecoration: "underline" }}
          >
            Privacy Policy
          </span>
        </p>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "center",
          borderTop: "none",
          padding: 0,
          paddingBottom: 25,
        }}
      >
        <Link
          to="/question/1"
          className="mainmodal__text"
          style={
            checkbox
              ? {
                  background: "#4380FF",
                  borderRadius: 40,
                  height: 48,
                  width: 153,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {
                  background: "#BFC0C8",
                  borderRadius: 40,
                  height: 48,
                  width: 153,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  pointerEvents: "none",
                }
          }
        >
          Okay
        </Link>
      </Modal.Footer>
    </Modal>
  );
}
export default MyVerticallyCenteredModal;
