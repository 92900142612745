import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ampProps } from '../../../utils/amplitude'
import { ConfirmButton, Header, QuestionBox } from '../../exporter'
import './AmazingComponent.scss'
function AmazingComponent(props) {
    const answer = () => {
      
    }
    return (
        <>
            <Header back={true} question={`${props.match.params.id}/${props.questions.length}`} progress={parseInt(props.match.params.id) / props.questions.length * 100} />
            <div className="amazing__block">
                <img className="block__img" src='/img/utils/like.png' />
                <p className="block__text1">Amazing! Now let’s continue customizing your Habit Plan!</p>
                <p className="block__text2">Please, answer the following questions as honestly as possible. Every answer you give will impact the personalization of the plan.</p>
                <ConfirmButton submitLink={() => answer()} text={props.questions[parseInt(props.match.params.id - 1)].skip} active={true} button={props.questions[parseInt(props.match.params.id - 1)].buttonText} link={`/question/${parseInt(props.match.params.id) + 1}`} />
            </div>
        </>
    )

}


export default connect(state => ({ questions: state.questions }), dispatch => ({
    newGame: () => {
        dispatch({ type: 'TEST' })
    }
}))(withRouter(AmazingComponent))