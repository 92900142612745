

export default [
  {
    componentType: "TEXT_IMAGE_ANSWERS_COMPONENT",
    title: "The checklist you can't do without!",
    secondText: "Let's make your checklist plan. Opt for your goals to get excited:",
    answers: ["Perform cleaning", "Keep mental health", "Be productive", "Set useful nutrition", "Love & be loved", "Have a healthy body", "Bring self-care into life"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: false
  }
  , {
    componentType: "TEXT_ANSWERS",
    title: 'Do you often procrastinate?',
    secondText: 'Procrastination is the action of delaying or postponing something',
    answers: ["Yes, but I want to change it", "Very rarely", "No, never"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: false
  }, {
    componentType: "TEXT_ANSWERS",
    title: 'Is it hard for you to stay focused?',
    secondText: '“How did I end up on this site?”',
    answers: ["Yes, I get easily distracted", "I can stay focused when I need to ", "Depends on the situation"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: false
  }, {
    componentType: "TEXT_AND_IMAGES_ANSWERS",
    title: 'Define your goal!',
    secondText: 'This information will help us to give you the best result:',
    answers: ["Get rid of unnecessary things in your life", "Become more self-aware ", "Gain clarity and boost your life"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: false
  }, {
    componentType: "IMAGES_WITH_SELECTORS_COMPONENT",
    title: 'Who among these people inspires you the most?',
    secondText: '',
    answers: ["Ellen DeGeneres", "Warren Buffet", "John F. Kennedy", "Albert Einstein"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: false
  }, {
    componentType: "AMAZING_COMPONENT",
    buttonText: 'Got it!',
  }, {
    componentType: "TEXT_ANSWERS",
    title: "What is your temperament type?",
    secondText: 'This information will help us to give you the best result',
    answers: ["Sanguine", "Choleric", "Melancholic", "Phlegmatic", "I don't know"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: false
  }, {
    componentType: "TEXT_AND_IMAGES_ANSWERS",
    title: 'What would you like to have?',
    secondText: 'What are you missing for a fulfilling life?',
    answers: ["More focus", "More strength", "More calm", "More energy"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: false
  }, {
    componentType: "AGE_ANSWERS",
    title: 'What is your timing goal?',
    secondText: 'How much time do you want to spend on self-development every day?',
    answers: ["5 min /", "10 min /", "15 min /", "20+ min /"],
    text: 'day',
    selected: null,
    answered: false,
    buttonText: 'Get result',
    skip: false
  }, {
    componentType: "TEXT_ANSWERS",
    title: "What's your experience with habit tracking?",
    secondText: 'Checklists, calendars, plans',
    answers: ["I’ve tried it a few times", "I use it regularly", "None"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: false
  }, {
    componentType: "EIGHT_ANSWERS_COMPONENT",
    title: 'Which of these habits you’d like to form?',
    secondText: '',
    answers: ["More water drinking", "Exercise", "Reading", "Proper nutrition", "Self-studying", "Meditating", "Healthy sleep","Yoga"],
    selected: [],
    answered: false,
    important: true,
    buttonText: 'Next',
    skip: false
  }, {
    componentType: "EIGHT_ANSWERS_COMPONENT",
    title: 'Are there any habits you’d like to quit?',
    secondText: '',
    answers: ["Smoking", "Social Media", "Drinking", "Fast food", "Procrastination", "Lack of sleep", "Nail biting", "Doubting yourself"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "DEAL_COMPONENT",
    buttonText: 'Got it!',
  }, {
    componentType: "TEXT_ANSWERS",
    title: "What’s the hardest part of forming a habit?",
    secondText: 'We will show you how best to approach it ',
    answers: ["Lack of motivation", "Not enough time", "I forget about it", "I don’t know how to start"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "TEXT_AND_IMAGES_ANSWERS",
    title: 'Do you doubt yourself after making a mistake?',
    secondText: 'Don’t worry. It’s ok to have doubtful mind nowadays',
    answers: ["No, I’m always confident", "Yeah, sometimes", "Yes, very often"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: false
  }, {
    componentType: "TEXT_AND_IMAGES_ANSWERS",
    title: 'Which habits do you need to form to work better?',
    secondText: 'Choose answer that suits you best:',
    answers: ["I want to learn how to manage my time", "I want to learn how to delegate my duties", "I would love to become a team player"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: false
  }, {
    componentType: "TEXT_ANSWERS",
    title: "Which kind of work motivates you most?",
    secondText: 'Choose answer that suits you best:',
    answers: ["I dislike office work but it gives me discipline ", "I can be motivated only by exciting projects ", "Freelance job is the best in the world "],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: false
  }, {
    componentType: "TEXT_ANSWERS",
    title: "What’s success for you?",
    secondText: 'Choose answer that suits you best:',
    answers: ["Success is the result of daily habits and arduous work ", " Success is the matter of fate", "Success comes if you know the right people "],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "TEXT_AND_IMAGES_ANSWERS",
    title: "What helps you with a habit-forming process?",
    secondText: 'Choose answer that suits you best:',
    answers: ["Having a habit-buddy", "Discipline", "Self-motivation is enough"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "TEXT_ANSWERS",
    title: "Is it important to discuss habits with people around?",
    secondText: 'Choose answer that suits you best:',
    answers: ["Highly important! I need their support", "Not so important. If some of them share my goals, it is enough", "Not important. I can form new habits with self-motivation "],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "TEXT_ANSWERS",
    title: "Are you happy with your work/life balance?",
    secondText: 'Choose answer that suits you best:',
    answers: ["No, I have burnt out and need some rest", "Sometimes I overwork and can't make myself do anything productive ", "I live in harmony but need some tips on optimizing my time for new habits"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "TEXT_ANSWERS",
    title: "What do you usually do when you are tired?",
    secondText: 'Choose answer that suits you best:',
    answers: ["I try some creative activities. I need inspiration!", "I hardly move, so just watch TV or sleep", "Most often, I spend time with friends and family"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "TEXT_ANSWERS",
    title: "How’d your life change if you formed new habits?",
    secondText: 'Choose answer that suits you best:',
    answers: ["I would be more self-organized", "I would have a higher life satisfaction", "My life would feel more balanced"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "TEXT_ANSWERS",
    title: "Which statement describes you best?",
    secondText: 'Choose answer that suits you best:',
    answers: [" I reward myself for each small step", "I feel irritation when I fail"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "TEXT_ANSWERS",
    title: "What motivates you better?",
    secondText: 'Choose answer that suits you best:',
    answers: ["Achievements of your family members and friends", "Inspirational speeches of celebrities"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "TEXT_ANSWERS",
    title: "How will you know you have formed a new habit?",
    secondText: 'Choose answer that suits you best:',
    answers: ["I stick to it for 21 consecutive days", "It’s easier for me to do it than not", "It starts to bring you joy"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "BILLY_COMPONENT",
    answered: false,
    buttonText: 'Got it',
    skip: false
  }, {
    componentType: "TEXT_ANSWERS2",
    title: "Turn for affairs",
    secondText: 'You study at university but suddenly your friend proposes to set up a business. What’s your move?',
    answers: ["I’ll try to be successful at both studying and business", "I finish my university first, it’s more important", "If it’s a great opportunity I’m willing to risk and drop out of the university"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "TEXT_ANSWERS2",
    title: "Money for a rainy day",
    secondText: 'You are saving on your dream trip. How will you save the money?',
    answers: ["I will cut down on daily expenses", "I will work more or take a second job", "I will try myself at entrepreneurship"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "TEXT_ANSWERS2",
    title: "People around you matter",
    secondText: "You see that people around you don't support your self-growth. What could be your reaction?",
    answers: ["I will stop interacting with such people ", "No need to explain. They will understand everything when they see my results", "I will find a way and explain why it is important for me"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "TEXT_ANSWERS2",
    title: "Do it like a pro ",
    secondText: "You just started learning a new language, but have little time to practice. What is your choice?",
    answers: ["I will spend 20 minutes to train daily", "I will study 6 hours on Saturday", "I will study when I have time"],
    selected: [],
    answered: false,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "DELIVERY_COMPONENT",
    title: "To deliver the most accurate plan we need a few more things",
    selected: [],
    answered: false,
    input1: null,
    input2: null,
    buttonText: 'Next',
    skip: true
  }, {
    componentType: "TEXT_AND_IMAGES_ANSWERS",
    title: 'Select your gender',
    secondText: 'This information will help us to give you the best result',
    answers: ["Female", "Male", "Other"],
    tag: 'gender',
    answered: false,
    buttonText: 'Next',
    skip: false
  }, {
    componentType: "AGE_ANSWERS",
    title: 'What is your age?',
    secondText: 'This information will help us to give you the best result',
    answers: ["18 - 29", "30 - 39", "40 - 49", "50+"],
    text: 'years',
    selected: [],
    tag: 'age',
    answered: false,
    buttonText: 'Get result',
    skip: false
  },
  {
    componentType: "CALCULATING"
  }

]