import React, { useEffect,useState } from 'react'
import { Redirect } from 'react-router-dom'

export default function Calculating(){
    const [redirect,setRedirect] = useState(false)
    useEffect(()=>{
        setTimeout(()=>{
            setRedirect(true)
        },10500)
    },[])
    if(redirect){
        return <Redirect to='/email'/>
    }else{
        return <img src="/gif/calculating.gif" style={{width:"100%"}} alt="img"/>
    }
}