import React from 'react'

import './styles.scss'

const Input = ({
	width: maxWidth,
	isValid,
	formValue,
	setFormValue,
	handleKeyboardSubmitEmail,
	placeholder,
}) => {
	return (
		<input
			type='email'
			name='email'
			id='email'
			className={`input ${isValid ? 'input--valid' : ''}`}
			value={formValue}
			onChange={e => setFormValue(e.target.value)}
			onKeyDown={e => handleKeyboardSubmitEmail(e)}
			placeholder={placeholder}
			style={{ maxWidth }}
		/>
	)
}

export default Input
