import React from 'react'
import "./ConfirmButton.scss"
import {Link} from 'react-router-dom'

export default function Button(props) {
    if(props.submit){
        return (
            <div className="confirm">
                <button className={(props.active)?"confirm__button confirm__button--active":"confirm__button confirm__button--unactive"} onClick={props.submit}>{props.button || "Let's Go!"}</button>
                <p className="confirm__text" style={(props.text)?{opacity:1}:{opacity:0}}>Skip for now</p>
            </div>
        )
    }else{
        return (
            <div className="confirm">
                <Link to={props.link} onClick={()=>props.submitLink()} className={(props.active || props.text)?"confirm__button confirm__button--active":"confirm__button confirm__button--unactive"}>{props.button || "Let's Go!"}</Link>
                <p className="confirm__text" style={(props.text)?{opacity:1}:{opacity:0}}>Skip for now</p>
            </div>
        )
    }
    
}