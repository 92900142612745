import React, { useEffect, useState } from "react";
import "./App.css";
import { Switch, Route, withRouter } from "react-router-dom";
import { Home, QuestionController, Email } from "./components/exporter";
import queryString from "query-string";
import { amp, ampSet } from "./utils/amplitude";
import Personality from "./components/personality/Personality";
import Success from "./components/Success/Success";
import Personality2 from "./components/personality/Personality2";
import Personality3 from "./components/personality/Personality3";
import Discount1 from "./components/Discount/Discount";
import Discount2 from "./components/Discount/Discount2";
import Discount3 from "./components/Discount/Discount3";
import Discount4 from "./components/Discount/Discount4";
import Upsale from "./components/Upsales/Upsale";
import Upsale2 from "./components/Upsales/Upsale2";
import PurchaseRegister from "./components/BillingModal/PurchaseRegister";
import Upsaletest from "./components/Upsales/Upsaletest";
import FailPayment from "./components/failPayment/FailPayment";
import Upsale3 from "./components/Upsales/Upsale3";
import { connect } from "react-redux";
import Upsaletest2 from "./components/Upsales/Upsaletest2";
import Personality4 from "./components/personality/Personality4";
import PDFViewer from "./components/common/PDFViewer";
import AccountScreen from "./components/Account/container";
function App(props) {
  const [flow, setFlow] = useState(parseInt(localStorage.getItem("flow_type")));
  // const [zoom, setZoom] = useState(
  //   window.innerWidth / 375 > 1.33 ? 1.33 : window.innerWidth / 375
  // );
  // useEffect(() => {
  //   window.addEventListener("resize", () =>
  //     setZoom(window.innerWidth / 375 > 1.33 ? 1.33 : window.innerWidth / 375)
  //   );
  // }, []);
  useEffect(() => {
    const parser = queryString.parse(props.location.search);
    if (!localStorage.getItem("search")) {
      localStorage.setItem("search", props.location.search);
    }
    if (parser.flow) {
      localStorage.setItem("type", "hello");
    }
    if (parser.utm_source) {
      props.setUtmSource({ utm_source: parser.utm_source });
    }
    window.scrollTo(0, 0);
  }, [props.location.pathname]);

  useEffect(() => {
    let params = queryString.parse(props.location.search);
    Object.keys(params).forEach((elem) => {
      ampSet(elem, params[elem]);
    });
  }, [props.location.search]);

  return (
    <div className="App">
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/question/:id" component={QuestionController} exact />
        <Route path="/email" component={Email} exact />
        <Route path="/personality" component={Personality} exact />
        <Route path="/personality2" component={Personality2} exact />
        <Route path="/personality3" component={Personality3} exact />
        <Route path="/personality4" component={Personality4} exact />
        <Route path="/success" component={Success} exact />
        <Route path="/register" component={PurchaseRegister} exact />
        <Route path="/discount" component={Discount1} exact />
        <Route path="/discount2" component={Discount2} exact />
        <Route path="/discount3" component={Discount3} exact />
        <Route path="/discount4" component={Discount4} exact />
        <Route path="/appoffer2" component={Upsaletest2} exact />
        <Route path="/appoffer" component={Upsaletest} exact />
        <Route path="/uptest" component={Upsale3} exact />
        <Route path="/choffer" component={Upsale2} exact />
        <Route path="/fail" component={FailPayment} exact />
        <Route path="/account" component={AccountScreen} exact />
        <Route
          path="/privacy-policy"
          component={() => (
            <PDFViewer url="/pdf/HabitBox - Privacy Policy - Website - February 2021.docx-2.pdf" />
          )}
        />
        <Route
          path="/terms-of-use"
          component={() => (
            <PDFViewer url="/pdf/Habit Box web+app - General Terms - April 2021.docx.pdf" />
          )}
        />
        <Route
          path="/subscription-terms"
          component={() => (
            <PDFViewer url="/pdf/HabitBox Web - General Subscription Terms - April 2021.docx.pdf" />
          )}
        />
        <Route
          path="/money-back"
          component={() => (
            <PDFViewer url="/pdf/HabitBox - Money-back Policy.docx.pdf" />
          )}
        />
        <Route path="/faq" component={() => <PDFViewer url="/pdf/FAQ.pdf" />} />
        <Route component={Home} />
      </Switch>
      <img src="/img/utils/plan.png" className="none-image" alt="img" />
      <img src="/img/utils/like.png" className="none-image" alt="img" />
      <img src="/img/utils/bill.png" className="none-image" alt="img" />
      <img src="/img/home/main.png" className="none-image" alt="img" />
      <img src="/img/home/close.png" className="none-image" alt="img" />
      <img src="/img/header/Back.png" className="none-image" alt="img" />
      <img src="/img/header/Close.png" className="none-image" alt="img" />
      <img src="/img/header/Contact.png" className="none-image" alt="img" />
      <img src="/img/header/Menu.png" className="none-image" alt="img" />
      <img src="/img/header/Privacy.png" className="none-image" alt="img" />
      <img src="/img/header/Terms.png" className="none-image" alt="img" />
      <img
        src="/img/questionbox/bottomline.png"
        className="none-image"
        alt="img"
      />
      <img src="/img/questionImages/1/1.png" className="none-image" alt="img" />
      <img src="/img/questionImages/1/2.png" className="none-image" alt="img" />
      <img src="/img/questionImages/1/3.png" className="none-image" alt="img" />
      <img src="/img/questionImages/4/1.png" className="none-image" alt="img" />
      <img src="/img/questionImages/4/2.png" className="none-image" alt="img" />
      <img src="/img/questionImages/4/3.png" className="none-image" alt="img" />
      <img src="/img/questionImages/5/1.png" className="none-image" alt="img" />
      <img src="/img/questionImages/5/2.png" className="none-image" alt="img" />
      <img src="/img/questionImages/5/3.png" className="none-image" alt="img" />
      <img src="/img/questionImages/5/4.png" className="none-image" alt="img" />
      <img src="/img/questionImages/8/1.png" className="none-image" alt="img" />
      <img src="/img/questionImages/8/2.png" className="none-image" alt="img" />
      <img src="/img/questionImages/8/3.png" className="none-image" alt="img" />
      <img src="/img/questionImages/8/4.png" className="none-image" alt="img" />

      <img
        src="/img/questionImages/15/1.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/questionImages/15/2.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/questionImages/15/3.png"
        className="none-image"
        alt="img"
      />

      <img
        src="/img/questionImages/16/1.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/questionImages/16/2.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/questionImages/16/3.png"
        className="none-image"
        alt="img"
      />

      <img
        src="/img/questionImages/19/1.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/questionImages/19/2.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/questionImages/19/3.png"
        className="none-image"
        alt="img"
      />

      <img
        src="/img/questionImages/33/1.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/questionImages/33/2.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/questionImages/33/3.png"
        className="none-image"
        alt="img"
      />

      <img src="/img/email/email.png" className="none-image" alt="img" />
      <img
        src="/img/personality/customers1.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/customers2.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/customers3.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/customers4.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/customers5.png"
        className="none-image"
        alt="img"
      />
      <img src="/img/personality/left.png" className="none-image" alt="img" />
      <img src="/img/personality/logo.png" className="none-image" alt="img" />
      <img
        src="/img/personality/mastercard.png"
        className="none-image"
        alt="img"
      />
      <img src="/img/personality/minus.png" className="none-image" alt="img" />
      <img
        src="/img/personality/personality1.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/personality2.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/personality3.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/personality4.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/personality5.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/personality6.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/personality7.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/personality8.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/personality9.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/personality10.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/personality11.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/personality12.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/personality13.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/personality14.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/personality15.png"
        className="none-image"
        alt="img"
      />
      <img src="/img/personality/plus.png" className="none-image" alt="img" />
      <img src="/img/personality/right.png" className="none-image" alt="img" />
      <img
        src="/img/personality/roadmap.png"
        className="none-image"
        alt="img"
      />
      <img src="/img/personality/rocket.png" className="none-image" alt="img" />
      <img src="/img/personality/visa.png" className="none-image" alt="img" />
      <img
        src="/img/personality/whiteblank1.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/whiteblank2.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/whiteblank3.png"
        className="none-image"
        alt="img"
      />
      <img
        src="/img/personality/whiteblank4.png"
        className="none-image"
        alt="img"
      />
    </div>
  );
}

export default connect(
  (state) => ({ user: state.user }),
  (dispatch) => ({
    setEmail: (payload) => {
      dispatch({ type: "SET_EMAIL", payload });
    },
    setUtmSource: (payload) => {
      dispatch({ type: "SET_UTM_SOURCE", payload });
    },
  })
)(withRouter(App));
