import React from "react";
import Button from "../../../../components/common/Button";

import "./styles.scss";
import { amp } from "../../../../utils/amplitude";

const AuthorizedUserInfo = () => {
  return (
    <div className="authorized-info">
      <p className="authorized-info__description">
        If your plan doesn’t work, we can make a refund
      </p>
      <a
        href="https://mail.google.com/mail/u/0/?fs=1&to=support@habit-box.me&tf=cm&su=Canceling subscription&body=Dear Mr/Mrs%0AI am sending you this written notice to request cancellation of my subscription."
        className="authorized-info__link"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          width={335}
          label="Make A Refund"
          onClick={() => amp("cancel_subscription")}
        />
      </a>
    </div>
  );
};

export default AuthorizedUserInfo;
