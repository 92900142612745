import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { amp, ampSet } from "../../utils/amplitude";
import { ConfirmButton, Header } from "../exporter";
import "./Email.scss";
var validator = require("email-validator");
function Email(props) {
  const [email, setEmail] = useState("");
  const [active, setActive] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const emailHandler = (e) => {
    setEmail(e.target.value);
  };
  const [checkboxes, setCheckboxes] = useState([
    {
      isChecked: true,
    },
    {
      isChecked: true,
    },
  ]);

  const isSelectedAllCheckboxes = !checkboxes[0].isChecked;
  const onClickCheckboxes = (idx) =>
    setCheckboxes(
      checkboxes.map((checkbox, index) => {
        if (idx === index) {
          checkbox.isChecked = !checkbox.isChecked;
        }
        return checkbox;
      })
    );
  useEffect(() => {
    amp("email_shown");
  }, []);
  useEffect(() => {
    setActive(validator.validate(email));
  }, [email]);
  const openInNewWindow = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };
  const submit = () => {
    ampSet("email", email);
    setActive(false);
    props.setEmail({ email: email });
    if (localStorage.getItem("type")) {
      window.open(
        `/personality3?good=${
          props.questions[10].selected.filter((elem) => elem).length
            ? props.questions[10].selected.filter((elem) => elem).length
            : 1
        }&bad=${
          props.questions[11].selected.filter((elem) => elem).length
            ? props.questions[11].selected.filter((elem) => elem).length
            : 1
        }&goal=${
          props.questions[8].selected ? props.questions[8].selected : 0
        }&email=${email}&firstClicked=${btoa(
          props.user.firstClicked
        )}&utm_source=${props.user.utm_source}`,
        "_top"
      );
    } else {
      window.open(
        `/personality?good=${
          props.questions[10].selected.filter((elem) => elem).length
            ? props.questions[10].selected.filter((elem) => elem).length
            : 1
        }&bad=${
          props.questions[11].selected.filter((elem) => elem).length
            ? props.questions[11].selected.filter((elem) => elem).length
            : 1
        }&goal=${
          props.questions[8].selected ? props.questions[8].selected : 0
        }&email=${email}&firstClicked=${btoa(
          props.user.firstClicked
        )}&utm_source=${props.user.utm_source}`,
        "_top"
      );
    }
  };
  return (
    <div className="email__block">
      <img className="email__image" src="/img/email/email.png" alt="img" />
      <p className="email__header">It’s time!</p>
      <p className="email__text">
        Perk up your inbox and leave an email to be guided
      </p>
      <input
        type="email"
        className={
          active
            ? "email__input email__input--active"
            : "email__input email__input--unactive"
        }
        placeholder="email@email.com"
        value={email}
        onChange={emailHandler}
      />
      <div className="confirm" style={{ position: "relative" }}>
        <button
          className={
            active && !isSelectedAllCheckboxes
              ? "confirm__button confirm__button--active"
              : "confirm__button confirm__button--unactive"
          }
          onClick={() => submit()}
        >
          Get my plan
        </button>
        <div className="email-screen-privacies">
          <div className="privacy">
            <div
              className={`privacy-checkbox ${
                checkboxes[0].isChecked ? "privacy-checkbox--checked" : ""
              }`}
              onClick={() => onClickCheckboxes(0)}
            >
              <img
                src="/img/email/arrow.png"
                alt="privacy-checkbox"
                className="privacy-checkbox__image"
              />
            </div>
            <p className="privacy-text">
              By continuing I agree with{" "}
              <a href="/terms-of-use" className="privacy-text__link">
                Terms of Service
              </a>
              ,{" "}
              <a href="/privacy-policy" className="privacy-text__link">
                Privacy Policy
              </a>
              ,{" "}
              <a href="/money-back" className="privacy-text__link">
                Money-Back Policy
              </a>
              ,{" "}
              <a href="/subscription-terms" className="privacy-text__link">
                Subscription terms
              </a>
              ,{" "}
              <a href="/privacy-policy" className="privacy-text__link">
                Cookie policy
              </a>
            </p>
          </div>
          <div className="privacy">
            <div
              className={`privacy-checkbox ${
                checkboxes[1].isChecked ? "privacy-checkbox--checked" : ""
              }`}
              onClick={() => onClickCheckboxes(1)}
            >
              <img
                src="/img/email/arrow.png"
                alt="privacy-checkbox"
                className="privacy-checkbox__image"
              />
            </div>
            <p className="privacy-text">
              I would like to receive updates about products, services, and
              special offers from Refine via email
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({ questions: state.questions, user: state.user }),
  (dispatch) => ({
    setEmail: (payload) => {
      dispatch({ type: "SET_EMAIL", payload });
    },
  })
)(withRouter(Email));
