import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { ampProps } from "../../../utils/amplitude";
import {
  ConfirmButton,
  Header,
  QuestionBox,
  QuestionBox2,
} from "../../exporter";
import "./DeliveryComponent.scss";
function TextAnswers(props) {
  const [active, setActive] = useState(0);
  const [button, setButton] = useState(false);
  const [answered, setAnswered] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const answer = () => {
    // console.log('hello')
    // ampProps("question_answered", { question: props.match.params.id })
    setActive(false);
  };
  useEffect(() => {
    if (answered) {
      setAnswered(false);
    }
  }, [answered]);
  useEffect(() => {
    if (active) {
      setButton(true);
    } else {
      setButton(false);
    }
  }, [active]);
  const clicked = () => {
    props.setData({ id: props.match.params.id - 1, input1, input2 });
    setAnimation(true);
    setTimeout(() => {
      setActive([false, false, false, false, false, false, false, false]);
      setAnimation(false);
      setAnswered(true);
    }, 500);
  };
  const input1Handler = (e) => {
    if (e.target.value > 99) {
      setInput1(input1);
    } else {
      setInput1(parseInt(e.target.value));
    }
  };
  const input2Handler = (e) => {
    if (e.target.value > 99) {
      setInput2(input2);
    } else {
      setInput2(parseInt(e.target.value));
    }
  };
  if (answered)
    return (
      <Redirect push to={`/question/${parseInt(props.match.params.id) + 1}`} />
    );
  return (
    <>
      <Header
        back={true}
        question={`${props.match.params.id}/${props.questions.length}`}
        progress={
          (parseInt(props.match.params.id) / props.questions.length) * 100
        }
      />
      <div
        className={
          animation
            ? "text-answers__block text-answers__block--fadeout"
            : "text-answers__block text-answers__block--fadein"
        }
      >
        <QuestionBox2
          title={props.questions[parseInt(props.match.params.id - 1)].title}
          text={props.questions[parseInt(props.match.params.id - 1)].secondText}
          progress={
            (parseInt(props.match.params.id) / props.questions.length) * 100
          }
        />
        <p className="delivery__text">
          How many habits have you formed in the last 6 months?
        </p>
        <input
          type="number"
          className={
            input1 || input1 === 0
              ? "delivery__input delivery__input--active"
              : "delivery__input delivery__input--unactive"
          }
          value={input1}
          placeholder="1"
          onChange={input1Handler}
        />
        <p className="delivery__text">
          How long on average does it take you to form a new habit in days?
        </p>
        <input
          type="number"
          className={
            input2
              ? "delivery__input delivery__input--active"
              : "delivery__input delivery__input--unactive"
          }
          value={input2}
          placeholder="14"
          onChange={input2Handler}
        />
      </div>
      <div className="confirm">
        <button
          className={
            input2 && (input1 || input1 === 0)
              ? "confirm__button confirm__button--active"
              : "confirm__button confirm__button--unactive"
          }
          onClick={() => clicked()}
          style={{ marginBottom: 20 }}
        >
          {props.questions[parseInt(props.match.params.id - 1)].buttonText}
        </button>
      </div>
    </>
  );
}

export default connect(
  (state) => ({ questions: state.questions }),
  (dispatch) => ({
    newGame: () => {
      dispatch({ type: "TEST" });
    },
    setData: (payload) => {
      dispatch({ type: "SET_HABBITS", payload });
    },
  })
)(withRouter(TextAnswers));
