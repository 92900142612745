import React from "react";
import { withRouter } from "react-router";
import { ProgressBar } from "react-bootstrap";
import "./Header.scss";
function Header(props) {
  return (
    <>
      <div className="header">
        <ProgressBar
          variant="warning"
          style={{ width: "100%", animation: "none" }}
          now={props.progress || 60}
        />
      </div>
    </>
  );
}
export default withRouter(Header);
