import React from "react";

import "./styles.scss";

const Header = ({
  isShowBackButton = false,
  isTransparent,
  isOpenBurgerMenu,
  toggleBurgerMenu,
  onClickBackHandler,
}) => (
  <div
    className={`header-top-container ${
      isOpenBurgerMenu ? "header-top-container--opened-burger" : ""
    }`}
    style={{
      background: isTransparent ? "transparent" : "",
    }}
  >
    <div className="header-top">
      <div
        className={`back-button ${isShowBackButton ? "" : "back-button--hide"}`}
        onClick={() => isShowBackButton && onClickBackHandler()}
      >
        <img
          src="/img/common/header/left-arrow.svg"
          alt="left-arrow"
          className="back-button__arrow"
        />
        <span className="back-button__text">Back</span>
      </div>
      <div className="header-top-logo">
        <img src="/img/common/header/logo.png" alt="logo" />
        <span>HabitBox</span>
      </div>
      <div
        className={`burger-icon ${isOpenBurgerMenu ? "burger-icon--open" : ""}`}
        onClick={toggleBurgerMenu}
      >
        <img
          src={`/img/common/burgerMenu/rectangle.svg`}
          alt="menu-icon"
          className="burger-icon__line burger-icon__line--top"
        />
        <img
          src={`/img/common/burgerMenu/rectangle.svg`}
          alt="menu-icon"
          className="burger-icon__line burger-icon__line--bottom"
        />
      </div>
    </div>
  </div>
);

export default Header;
