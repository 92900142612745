import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ampProps } from '../../../utils/amplitude'
import { ConfirmButton, Header, QuestionBox } from '../../exporter'
import './BillyComponent.scss'
function AmazingComponent(props) {
    const answer = () => {
        // ampProps("question_answered", { question: props.match.params.id })
    }
    return (
        <>
            <Header back={true} question={`${props.match.params.id}/${props.questions.length}`} progress={parseInt(props.match.params.id) / props.questions.length * 100} />
            <div className="billy__block">
                <img className="block__img" src='/img/utils/bill.png' />
                <p className="block__text1" >Every day Bill Gates spends one hour on education activities. His habit is to learn something new.</p>
                <p className="block__text2">Maybe that could be your next habity?</p>
                <ConfirmButton submitLink={() => answer()} text={props.questions[parseInt(props.match.params.id - 1)].skip} active={true} button={props.questions[parseInt(props.match.params.id - 1)].buttonText} link={`/question/${parseInt(props.match.params.id) + 1}`} />
            </div>
        </>
    )

}


export default connect(state => ({ questions: state.questions }), dispatch => ({
    newGame: () => {
        dispatch({ type: 'TEST' })
    }
}))(withRouter(AmazingComponent))