import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { ampProps } from "../../../utils/amplitude";
import { ConfirmButton, Header, QuestionBox } from "../../exporter";
import "./EightAnswers.scss";
function EightAnswers(props) {
  const [active, setActive] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [button, setButton] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const activeHandler = (index) => {
    let activeItems = [...active];
    activeItems[index] = !activeItems[index];
    setActive(activeItems);
  };
  useEffect(() => {
    if (redirect) {
      setRedirect(false);
    }
  }, [redirect]);
  const clicked = () => {
    props.setAnswer({ id: props.match.params.id - 1, numbers: active });
    setAnimation(true);
    setTimeout(() => {
      setActive([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ]);
      setAnimation(false);
      setRedirect(true);
    }, 500);
  };
  useEffect(() => {
    let flag = false;
    active.forEach((elem) => {
      if (elem) {
        setButton(active);
        flag = true;
      }
    });
    if (!flag) setButton(false);
  }, [active]);
  if (redirect)
    return (
      <Redirect push to={`/question/${parseInt(props.match.params.id) + 1}`} />
    );
  return (
    <>
      <Header
        back={props.match.params.id === 1 ? false : true}
        question={`${props.match.params.id}/${props.questions.length}`}
        progress={
          (parseInt(props.match.params.id) / props.questions.length) * 100
        }
      />
      <div
        className={
          animation
            ? "eight-answers__block eight-answers__block--fadeout"
            : "eight-answers__block eight-answers__block--fadein"
        }
      >
        <QuestionBox
          title={props.questions[parseInt(props.match.params.id - 1)].title}
          text={props.questions[parseInt(props.match.params.id - 1)].secondText}
          progress={
            (parseInt(props.match.params.id) / props.questions.length) * 100
          }
        />
        <div className="block__container">
          {props.questions[parseInt(props.match.params.id - 1)].answers.map(
            (elem, index) => {
              return (
                <div
                  key={index}
                  className={
                    active[index]
                      ? `container__item container__item--active bubble${
                          index + 1
                        }`
                      : `container__item container__item--unactive bubble${
                          index + 1
                        }`
                  }
                  onClick={() => activeHandler(index)}
                >
                  <p
                    className={
                      active[index]
                        ? "container__text container__text--active"
                        : "container__text container__text--unactive"
                    }
                  >
                    {elem}
                  </p>
                </div>
              );
            }
          )}
        </div>
      </div>
      {/* <ConfirmButton submitLink={() => answer()} text={props.questions[parseInt(props.match.params.id - 1)].skip} active={button} button={props.questions[parseInt(props.match.params.id - 1)].buttonText} link={`/question/${parseInt(props.match.params.id) + 1}`} /> */}
      <div className="confirm">
        <button
          className={
            button
              ? "confirm__button confirm__button--active"
              : "confirm__button confirm__button--unactive"
          }
          onClick={() => clicked()}
          style={{ marginBottom: 20 }}
        >
          {props.questions[parseInt(props.match.params.id - 1)].buttonText}
        </button>
        {props.questions[parseInt(props.match.params.id - 1)].skip ? (
          <p
            className="confirm__text"
            style={{ marginTop: -10 }}
            onClick={() => clicked()}
          >
            Skip
          </p>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default connect(
  (state) => ({ questions: state.questions }),
  (dispatch) => ({
    newGame: () => {
      dispatch({ type: "TEST" });
    },
    setAnswer: (payload) => {
      dispatch({ type: "MULTI_ANSWER", payload });
    },
  })
)(withRouter(EightAnswers));
