import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { amp, ampProps, ampSet } from '../../utils/amplitude'
import './Upsale.scss'
import { writeUserData } from '../../utils/firebaseStorage'
import { trackPurchase } from './trackPurchase'


function Upsale(props) {
    const [successRedirect, setSuccessRedirect] = useState(false)
    const [price, setPrice] = useState(999)
    const [disabled, setDisabled] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            ampProps('upsell_shown_app')
        }, 0)
        ampSet('upsale_test', 'original')
    }, [])
    const upgradeOrder = () => {
        console.log('hello1')
        setDisabled(!disabled)
        ampSet('upsale_app', 'upgraded')
        console.log(props.user)
        if (props.user.cardToken && !props.user.upsale1) {
            console.log('hello2')
            fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/card/${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? 'test' : 'live'}/recure`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'auth': (process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_CARD_TEST_MERCHANT : process.env.REACT_APP_CARD_LIVE_MERCHANT
                },
                body: JSON.stringify({ amount: price, email: props.user.email || 'emailnotentered@gmail.com', token: props.user.cardToken })

            }).then((res) => {
                fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/mailfire/app`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "auth": process.env.REACT_APP_MAILFIRE
                    },
                    body: JSON.stringify({
                        email: props.user.email
                    })
                }).then((res) => {
                    trackPurchase(price, 'purchase_app')
                    writeUserData(props.user.email, "PREMIUM")
                    props.setUpsale()
                    props.setBillPrice({ price: props.user.price + price })
                    setDisabled(!disabled)
                    window.open('/choffer', '_top')
                })
            })
        } else if (props.user.paypalToken && !props.user.upsale1) {
            fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/paypal/${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? 'test' : 'live'}/recure`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'auth': (process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_PAYPAL_TEST_MERCHANT : process.env.REACT_APP_PAYPAL_LIVE_MERCHANT
                },
                body: JSON.stringify({ amount: price, email: props.user.email || 'emailnotentered@gmail.com', token: props.user.paypalToken })

            }).then((res) => {
                fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/mailfire/app`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "auth": process.env.REACT_APP_MAILFIRE
                    },
                    body: JSON.stringify({
                        email: props.user.email
                    })
                }).then((res) => {
                    trackPurchase(price, 'purchase_app')
                    writeUserData(props.user.email, "PREMIUM")
                    props.setUpsale()
                    props.setBillPrice({ price: props.user.price + price })
                    setDisabled(!disabled)
                    window.open('/choffer', '_top')
                })
            })
        } else {
            setDisabled(!disabled)
            window.open('/choffer', '_top')
        }
    }
    if (successRedirect) return <Redirect push to={{ pathname: '/choffer' }} />
    return (
        <div className="upsale__screen1">
            <p className="screen1__text1">Boost your personal <span style={{ color: '#2e71ff' }}>growth with App HabitBox</span></p>
            <div className="screen1__lineblock">
                <div className="lineblock__check">
                    <img className="check__img" src='/img/pick.png' />
                </div>
                <p className="lineblock__text" >Raise your motivation by following personal challenges program </p>
            </div>
            <div className="screen1__lineblock">
                <div className="lineblock__check">
                    <img className="check__img" src='/img/pick.png' />
                </div>
                <p className="lineblock__text" >Develop new useful habits. HabitBox will push you for amazing results </p>
            </div>
            <div className="screen1__lineblock">
                <div className="lineblock__check">
                    <img className="check__img" src='/img/pick.png' />
                </div>
                <p className="lineblock__text" >Create individual challenges and monitor the result</p>
            </div>
            <img className="upsale__img" src='/img/upsale1.png' />
            <div className="upsale__whiteblock">
                <p className="whiteblock__text1"><strike style={{ color: '#FF4B12' }}>USD 25</strike> USD {price / 100} will be added to your order</p>
                <button className="whiteblock__button" style={(disabled) ? { pointerEvents: 'none', opacity: 0.5 } : {}} onClick={() => upgradeOrder()}>Upgrade My Order </button>
                <button onClick={() => { ampSet('upsale_app', 'declined'); window.open('/choffer', '_top') }} className="whiteblock__undertext" >No, thank you</button>
            </div>
        </div>
    )
}
export default connect(state => ({ user: state.user }), dispatch => ({
    setEmail: (payload) => {
        dispatch({ type: 'SET_EMAIL', payload })
    },
    setReload: () => {
        dispatch({ type: 'SET_RELOAD' })
    },
    setUpsale: () => {
        dispatch({ type: 'SET_UPSALE_1' })
    },
    setBillPrice: (payload) => {
        dispatch({ type: 'SET_PAID_PRICE', payload })
    }
}))(withRouter(Upsale))
