import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { ampProps } from "../../../utils/amplitude";
import { ConfirmButton, Header, QuestionBox } from "../../exporter";
import "./ImagesWithSelectors.scss";
function ImagesWithSelectors(props) {
  const [active, setActive] = useState([false, false, false, false]);
  const [button, setButton] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const clicked = () => {
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
      setRedirect(true);
    }, 500);
  };
  const activeHandler = (index) => {
    let activeItems = [...active];
    activeItems[index] = !activeItems[index];
    setActive(activeItems);
  };
  useEffect(() => {
    let flag = false;
    active.forEach((elem) => {
      if (elem) {
        setButton(active);
        flag = true;
      }
    });
    if (!flag) setButton(false);
  }, [active]);
  if (redirect)
    return (
      <Redirect push to={`/question/${parseInt(props.match.params.id) + 1}`} />
    );
  return (
    <>
      <Header
        back={true}
        question={`${props.match.params.id}/${props.questions.length}`}
        progress={
          (parseInt(props.match.params.id) / props.questions.length) * 100
        }
      />
      <div
        className={
          animation
            ? "text-image-answers__block text-image-answers__block--fadeout"
            : "text-image-answers__block text-image-answers__block--fadein"
        }
      >
        <QuestionBox
          title={props.questions[parseInt(props.match.params.id - 1)].title}
          text={props.questions[parseInt(props.match.params.id - 1)].secondText}
          progress={
            (parseInt(props.match.params.id) / props.questions.length) * 100
          }
        />
        <div className="block__container">
          {props.questions[parseInt(props.match.params.id - 1)].answers.map(
            (elem, index) => {
              return (
                <div
                  key={index}
                  className={
                    active[index]
                      ? "container__item container__item--active"
                      : "container__item container__item--unactive"
                  }
                  onClick={() => activeHandler(index)}
                >
                  <img
                    className="container-item__img"
                    src={`/img/questionImages/${props.match.params.id}/${
                      index + 1
                    }.png`}
                    alt="img"
                  />
                  <p
                    className={
                      active[index]
                        ? "container-item__text container-item__text--active"
                        : "container-item__text container-item__text--unactive"
                    }
                    style={{ maxWidth: 90 }}
                  >
                    {elem}
                  </p>
                  <img
                    src={`/img/questionImages/${
                      props.match.params.id
                    }/selector_${active[index] ? "active" : "unactive"}.png`}
                    style={{
                      height: 23,
                      position: "absolute",
                      right: 15,
                      top: 15,
                    }}
                  />
                </div>
              );
            }
          )}
        </div>
      </div>
      <div className="confirm">
        <button
          className={
            button
              ? "confirm__button confirm__button--active"
              : "confirm__button confirm__button--unactive"
          }
          onClick={() => clicked()}
          style={{ marginBottom: 20 }}
        >
          {props.questions[parseInt(props.match.params.id - 1)].buttonText}
        </button>
      </div>
    </>
  );
}

export default connect((state) => ({ questions: state.questions }))(
  withRouter(ImagesWithSelectors)
);
