import React from "react";
import { Link } from "react-router-dom";

import "./Footer.scss";

const Footer = () => {
  return (
    <div className="sale-footer">
      <img
        src="/img/footer1.png"
        alt="sale-footer"
        className="sale-footer__logos"
      />
      <div className="sale-footer-links">
        <a
          href="https://mail.google.com/mail/?view=cm&fs=1&to=support@habit-box.me"
          target="_blank"
          rel="noreferrer"
          className="link "
        >
          Contact us
        </a>
        <Link className="link" to={`/privacy-policy`}>
          Privacy policy
        </Link>
        <Link className="link" to="/terms-of-use">
          Terms of Service
        </Link>
      </div>
      <div className="money-back-container">
        <div className="money-back-wrapper">
          <div className="money-back">
            <h3 className="money-back__title">Money-back guarantee</h3>
            <p className="money-back__description">
              <b>14-day money back guarantee</b>, which entitles you to a full
              refund upon cancellation and emailing
              <a
                href="https://mail.google.com/mail/?view=cm&fs=1&to=support@support@habit-box.me"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                support@habit-box.me
              </a>{" "}
              to request a refund. Such refund requests must be made within the
              first 14 calendar days from your first date of payment.
            </p>
            <img
              src="/img/ribbon.png"
              alt="money-back-ribbon"
              className="money-back__ribbon"
            />
          </div>
        </div>
      </div>
      <span className="sale-footer__adress">
        GM Unicorn Corporation Limited Florinis 7, Greg Tower,2nd Floor, 1065,
        Nicosia, Cyprus
      </span>
    </div>
  );
};

export default Footer;
