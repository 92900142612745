import React from "react";

import "./styles.scss";

const DEFAULT_BUTTON_HEIGHT = 50;
const DEFAULT_FIXED_BOTTOM_VALUE = 11;
const TWO = 2;

const Button = ({
  width: maxWidth,
  height = DEFAULT_BUTTON_HEIGHT,
  label,
  onClick,
  isBottomFixed = false,
  isDisabled = false,
  bottom = DEFAULT_FIXED_BOTTOM_VALUE,
  color,
  icon,
}) => {
  return !isBottomFixed ? (
    <button
      className={`button  ${isDisabled ? "button--disabled" : ""}`}
      onClick={onClick}
      disabled={isDisabled}
      style={{
        maxWidth,
        height,
        background: color,
      }}
    >
      {icon && (
        <img
          src={`/images/common/button-icons/${icon}.svg`}
          alt="button-icon"
          className="button__icon"
        />
      )}
      {label}
    </button>
  ) : (
    <button
      className={`button ${isBottomFixed ? "button--fixed" : ""} ${
        isDisabled ? "button--disabled" : ""
      }`}
      onClick={onClick}
      disabled={isDisabled}
      style={{
        maxWidth,
        height,
        left: `calc(50% - ${maxWidth / TWO}px)`,
        bottom,
      }}
    >
      {label}
    </button>
  );
};

export default Button;
