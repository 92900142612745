import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import { ampProps, ampSet } from "../../../utils/amplitude";
import { ConfirmButton, Header, QuestionBox } from "../../exporter";
import "./TextAndImageComponent.scss";

const data = {
  "Perform cleaning": "Cleaning",
  "Keep mental health": "Mental & Physical Health",
  "Be productive": "Personal Development",
  "Set useful nutrition": "Nutrition",
  "Love & be loved": "Sex & Love",
  "Have a healthy body": "Sport",
  "Bring self-care into life": "Self-Care",
};

function TextAnswers(props) {
  const [active, setActive] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [button, setButton] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const activeHandler = (index) => {
    if (!props.user.firstClicked) {
      props.setFirstClick({
        firstClicked:
          data[
            props.questions[parseInt(props.match.params.id - 1)].answers[index]
          ],
      });
    }
    let activeItems = [...active];
    activeItems[index] = !activeItems[index];
    setActive(activeItems);
  };
  useEffect(() => {}, [active]);

  useEffect(() => {
    if (redirect) {
      setRedirect(false);
    }
  }, [redirect]);
  const clicked = () => {
    props.setAnswer({ id: props.match.params.id - 1, numbers: active });
    setAnimation(true);
    setTimeout(() => {
      setActive([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ]);
      setAnimation(false);
      setRedirect(true);
    }, 400);
  };
  const clicked2 = () => {
    console.log(active);
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
      setRedirect(true);
    }, 400);
  };
  useEffect(() => {
    let flag = false;
    active.forEach((elem) => {
      if (elem) {
        setButton(active);
        flag = true;
      }
    });
    if (!flag) setButton(false);
  }, [active]);
  if (redirect)
    return (
      <Redirect push to={`/question/${parseInt(props.match.params.id) + 1}`} />
    );
  return (
    <>
      <Header
        question={`${props.match.params.id}/${props.questions.length}`}
        back={
          !(
            props.location.pathname === "/question/1" ||
            props.location.pathname === "/question/1/"
          )
        }
        progress={
          (parseInt(props.match.params.id) / props.questions.length) * 100
        }
      />
      <div
        className={
          animation
            ? "text-answers__block text-answers__block--fadeout"
            : "text-answers__block text-answers__block--fadein"
        }
      >
        <QuestionBox
          title={props.questions[parseInt(props.match.params.id - 1)].title}
          text={props.questions[parseInt(props.match.params.id - 1)].secondText}
        />
        <div className="block__container--row" style={{ marginBottom: 80 }}>
          {props.questions[parseInt(props.match.params.id - 1)].answers.map(
            (elem, index) => {
              return (
                <div
                  key={index}
                  className={
                    active[index]
                      ? "container__item container__item--active"
                      : "container__item container__item--unactive"
                  }
                  style={
                    index === active.length - 1 ? { borderBottom: "none" } : {}
                  }
                  onClick={() => activeHandler(index)}
                >
                  <div
                    className={
                      active[index]
                        ? "container-item__checkbox-container checkbox-container--active"
                        : "container-item__checkbox-container checkbox-container--unactive"
                    }
                  >
                    <i
                      className="fas fa-check"
                      style={{ color: "white", fontSize: 14, marginTop: 1 }}
                    />
                  </div>
                  <img
                    src={`/img/questionImages/${props.match.params.id}/${
                      index + 1
                    }.png`}
                    className="container-item__img"
                    alt="img"
                  />
                  <p
                    className={
                      active[index]
                        ? "container-item__text container-item__text--active"
                        : "container-item__text container-item__text--unactive"
                    }
                    style={
                      active[index]
                        ? {
                            color: "#286EFF",
                            marginTop: 4,
                            marginLeft: 9,
                            fontWeight: 600,
                          }
                        : { marginTop: 4, marginLeft: 9 }
                    }
                  >
                    {elem}
                  </p>
                </div>
              );
            }
          )}
        </div>
        <div className="confirm">
          <button
            className={
              button
                ? "confirm__button confirm__button--active"
                : "confirm__button confirm__button--unactive"
            }
            onClick={() => clicked()}
            style={{ marginBottom: 20 }}
          >
            {props.questions[parseInt(props.match.params.id - 1)].buttonText}
          </button>
          {props.questions[parseInt(props.match.params.id - 1)].skip ? (
            <p
              className="confirm__text"
              style={{ marginTop: -10 }}
              onClick={() => clicked2()}
            >
              Skip
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default connect(
  (state) => ({ questions: state.questions, user: state.user }),
  (dispatch) => ({
    newGame: () => {
      dispatch({ type: "TEST" });
    },
    setAnswer: (payload) => {
      dispatch({ type: "MULTI_ANSWER", payload });
    },
    setFirstClick: (payload) => {
      dispatch({ type: "SET_FIRST_CLICK", payload });
    },
  })
)(withRouter(TextAnswers));
