const sendChallenge = async (email,cb) => {
    console.log(email)
    let data = await fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/mailfire/challenge`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "auth": process.env.REACT_APP_MAILFIRE
        },
        body: JSON.stringify({
            email
        })
    })
    cb()
    return data
}
const sendChecklist = async(email,cb) => {
    console.log(email)
    let data = await fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/mailfire/checklist`, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "auth": process.env.REACT_APP_MAILFIRE
        },
        body: JSON.stringify({
            email
        })
    })
    cb()
    return data
}


export default async function selectorSender(arr,email,cb) {
    console.log("hello")
    if (arr[0]) {
        Promise.all([sendChallenge.call(this,email,()=>{}), sendChecklist.call(this, email,cb)], (res) => {
            return res
        })
    } else if (arr[1]) {
        let data = await sendChallenge(email,cb)
        return data
    } else if (arr[2]) {
        let data = await sendChecklist(email,cb)
        return data
    }

}