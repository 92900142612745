import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { amp } from "../../utils/amplitude";
import "./failPayment.scss";
function FailPayment(props) {
  useEffect(() => {
    amp("error_screen_shown");
    console.log(props.location.state.redirectUrl + "?modal=true");
  }, []);
  return (
    <div className="success">
      <img
        className="success__img"
        src="/img/fail.png"
        alt="img"
        style={{ marginBottom: 50 }}
      />
      <p
        className="success__header"
        style={{ marginTop: -40, position: "relative" }}
      >
        Ooops
      </p>
      <p className="success__body" style={{ marginTop: 0 }}>
        Sorry, your transaction failed:
      </p>
      <p className="success__underbody" style={{ color: "red" }}>
        {props.location.state.error}
      </p>
      <Link
        className="success__button"
        to={props.location.state.redirectUrl + "?modal=true"}
      >
        Try Again
      </Link>
    </div>
  );
}

export default connect(
  (state) => ({ questions: state.questions, user: state.user }),
  (dispatch) => ({
    setEmail: (payload) => {
      dispatch({ type: "SET_EMAIL", payload });
    },
  })
)(withRouter(FailPayment));
