import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { amp, ampProps, ampSet } from '../../utils/amplitude'
import { EightAnswers, TextAnswers, TextAndImageAnswers, TextAndImageComponent, Calculating, SixAnswers, AgeComponent, ImagesWithSelectors, BillyComponent, AmazingComponent, DealComponent, TextAnswers2, DeliveryComponent } from '../exporter'
const queryString = require('query-string');
function QuestionController(props) {
    const [flag, setFlag] = useState(false)
    useEffect(() => {
        setFlag(true)
    }, [])
    useEffect(() => {
        console.log('hello')
        if(!localStorage.getItem('onboarded')){
            localStorage.setItem('onboarded','true')
            setTimeout(() => {
                amp('onboarding_shown')
            }, 0)    
        }
    }, [])
    useEffect(() => {
        if (props.match.params.id === '12' || props.match.params.id === '13') {
            let indexes = []
            props.questions[parseInt(props.match.params.id - 2)].selected.forEach((elem, index) => {
                if (elem) {
                    indexes.push(index)
                }
            })
            indexes = indexes.map((elem) => {
                return props.questions[parseInt(props.match.params.id - 2)].answers[elem]
            })
            ampProps('question_answered', { question: props.match.params.id - 1, answer: indexes })
            return
        }
        if (props.match.params.id !== '1' && flag) {
            ampProps('question_answered', { question: props.match.params.id - 1 })
            return
        }
    }, [props.location.pathname])
    switch (props.questions[parseInt(props.match.params.id - 1)].componentType) {
        case "EIGHT_ANSWERS_COMPONENT": {
            return <EightAnswers />
        }
        case "TEXT_ANSWERS": {
            return <TextAnswers />
        }
        case "TEXT_IMAGE_ANSWERS_COMPONENT": {
            return <TextAndImageComponent />
        }
        case "TEXT_ANSWERS2": {
            return <TextAnswers2 />
        }
        case "IMAGES_WITH_SELECTORS_COMPONENT": {
            return <ImagesWithSelectors />
        }
        case "TEXT_AND_IMAGES_ANSWERS": {
            return <TextAndImageAnswers />
        }
        case "SIX_ANSWERS": {
            return <SixAnswers />
        }
        case "AGE_ANSWERS": {
            return <AgeComponent />
        }
        case "CALCULATING": {
            return <Calculating />
        }
        case "AMAZING_COMPONENT": {
            return <AmazingComponent />
        }
        case "DELIVERY_COMPONENT": {
            return <DeliveryComponent />
        }
        case "DEAL_COMPONENT": {
            return <DealComponent />
        }
        case "BILLY_COMPONENT": {
            return <BillyComponent />
        }
        default: return <p>Component now found</p>
    }
}
export default connect(state => ({ questions: state.questions }), dispatch => ({
    newGame: () => {
        dispatch({ type: 'TEST' })
    },
    setFirstFlow: () => {
        dispatch({ type: 'SET_FIRST_FLOW' })
    },
    setSecondFlow: () => {
        dispatch({ type: 'SET_SECOND_FLOW' })
    }
}))(withRouter(QuestionController))