import { ampProps } from "../../utils/amplitude";
import { v4 as uuidv4 } from 'uuid'

const prices = {
    '0': '2999',
    '69': '3196',
    '99': '3196'
}

const trackPurchase = (price, eventName) => {
    let id = uuidv4()
    ampProps(eventName, { value: price / 100 })
    window.gtag("event", "purchase", { transaction_id: id, currency: "USD", value: prices[price] / 100 || price / 100 })
    window.fbq('track', 'AddPaymentInfo', { currency: "USD", value: price / 100 });
    window.fbq('track', 'AddToCart', { currency: "USD", value: price / 100 });
    window.fbq('track', 'InitiateCheckout', { currency: "USD", value: price / 100 });
}
export { trackPurchase }