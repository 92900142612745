import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

import { v4 as uuidv4 } from 'uuid';

var config = {
    apiKey: process.env.REACT_APP_GOOGLE1,
    authDomain: process.env.REACT_APP_GOOGLE2,
    databaseURL: process.env.REACT_APP_GOOGLE3
};
firebase.initializeApp(config);
console.log(firebase)
var database = firebase.database();

function writeUserData(email, billing) {
    firebase.database().ref('users/' + uuidv4()).set({
        email, billing
    });
}

export { writeUserData }