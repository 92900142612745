import { Modal, Button, Spinner } from 'react-bootstrap'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { amp, ampProps, ampSet } from '../../utils/amplitude'

function MyVerticallyCenteredModal(props) {
    
    return (
        <Modal
            {...props}
            size="l"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* <Modal.Header style={{ flexDirection: 'row-reverse', borderBottom: 0 }}>
                <img className="header-icon" src="img/Union3.png" style={{ height: 16, width: 16,cursor:'pointer' }} alt="img" onClick={() => props.onHide()} />
            </Modal.Header> */}
            <Modal.Body style={{ padding: 25 }}>
               <p className="upsale-modal__text1">Add a personalized habit-making program <br/><span style={{color:'#4380FF'}}>for better result</span></p>
               <p className="upsale-modal__text2">HabitBox can analyze your daily, weekly and monthly progress in one place!</p>
               <img src="/img/personality/personality50.png" style={{width:"100%"}}/>
               <p className="upsale-modal__text3"><strike style={{color:"#FF4B12",fontWeight:600}}>USD 25</strike> USD 9.99 will be added to your order</p>
               <button className="upsale__button" onClick={()=>{props.setPrice();props.onHide();props.setUpsale();ampSet('upsale_app','upgraded')}}>Add to my Program</button>
               <p className="upsale-modal__text4" onClick={()=>{props.onHide();ampSet('upsale_app','declined')}}>No, thank you</p>
            </Modal.Body>
        </Modal>
    );
}
export default connect(state => ({ state }), dispatch => ({
    setAppFlow: (payload) => {
        dispatch({type:"SET_APP_FLOW",payload })
    },
    setUpsale: () => {
        dispatch({ type: 'SET_UPSALE_1' })
    }
}))
    (withRouter(MyVerticallyCenteredModal));

