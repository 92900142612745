import firstFlow from './firstFlow'
import secondFlow from './secondFlow'



export default function questionReducer(state = firstFlow, action) {
  switch (action.type) {
    case "SINGLE_ANSWER": {
      state[action.payload.id].answered = true
      state[action.payload.id].selected = action.payload.number
      return [...state]
    }
    case "SET_HABBITS":{
      state[action.payload.id].answered = true
      state[action.payload.id].input1 = action.payload.input1
      state[action.payload.id].input2 = action.payload.input2
      return [...state]
    }
    case "NO_ANSWER": {
      state[action.payload.id].answered = true
      return [...state]
    }
    case "SET_FIRST_FLOW":{
      return [...firstFlow]
    }
    case "SET_SECOND_FLOW":{
      return [...secondFlow]
    }
    case "MULTI_ANSWER": {
      state[action.payload.id].answered = true
      state[action.payload.id].selected = [...action.payload.numbers]
      return [...state]
    }
    default: {
      return state
    }
  }
}