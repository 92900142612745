import amplitude from 'amplitude-js'

const ampInst = amplitude.getInstance();
ampInst.init('7ab3de01538461056bda29ceb61f1c9b')

function amp(event){
    ampInst.logEvent(event)
}
function ampProps(event,props){
    ampInst.logEvent(event,props)
}
function ampSet(key,value){
    let identify = new amplitude.Identify().set(key, value)
    ampInst.identify(identify);
}

export {amp,ampProps,ampSet}