import React, { useEffect, useState } from "react";
import * as EmailValidator from "email-validator";

import "./styles.scss";
import AuthorizeForm from "../components/AuthorizeForm";
import WarningMessage from "../components/WarningMessage";
import AuthorizedUserInfo from "../components/AuthorizedUserInfo";
import { amp } from "../../../utils/amplitude";

const ENTER_KEYCODE = 13;

const AccountScreen = () => {
  const [formValue, setFormValue] = useState("");
  const [isFoundEmail, setIsFoundEmail] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const isValidEmail = EmailValidator.validate(formValue);

  const validateEmailFetch = async (email) =>
    await fetch(
      `https://us-central1-service-for-web-app.cloudfunctions.net/isPremium/${process.env.REACT_APP_PROJECT_STAGE}/${process.env.REACT_APP_PROJECT_NAME}/${email}`
    ).then((response) => response.json());

  const handleKeyboardSubmitEmail = (e) => {
    const { keyCode } = e;
    if (keyCode === ENTER_KEYCODE && isValidEmail) {
      onSubmitEmail();
    }
  };

  const onSubmitEmail = () => {
    const { premium } = validateEmailFetch(formValue);
    if (premium) {
      amp("authorization_completed");
      setIsFoundEmail(true);
      setIsAuthorized(true);
      return;
    }
    setIsFoundEmail(false);
  };
  useEffect(() => {
    amp("authorization_email");
  }, []);

  return (
    <div className="account">
      {!isFoundEmail && <WarningMessage />}
      <h2 className="account__header">My Account</h2>
      {isAuthorized ? (
        <AuthorizedUserInfo />
      ) : (
        <AuthorizeForm
          isValidEmail={isValidEmail}
          formValue={formValue}
          setFormValue={setFormValue}
          handleKeyboardSubmitEmail={handleKeyboardSubmitEmail}
          onSubmitEmail={onSubmitEmail}
        />
      )}
    </div>
  );
};

export default AccountScreen;
