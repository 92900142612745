import React, { useEffect, useState } from "react";
import "./Discount.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Slider from "./Slider/Slider";
import { Link } from "react-router-dom";
import { Card, Accordion } from "react-bootstrap";
import MyVerticallyCenteredModal from "../BillingModal/BillingModal";
import MyVerticallyCenteredModal2 from "../personality/Modal";
import { Modal, Button } from "react-bootstrap";
import { amp, ampSet } from "../../utils/amplitude";
import Footer from "../Footer/Footer";
import queryString from "query-string";

function Personality(props) {
  const [price, setPrice] = useState(469);
  const [show, setShow] = useState(false);
  const [promo, setPromo] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    setTimeout(() => {
      amp("discount_screen_shown");
    }, 0);
    let params = queryString.parse(props.location.search);
    if (params.email) {
      if (Array.isArray(params.email)) {
        props.setEmail({ email: params.email[0] });
      } else {
        props.setEmail({ email: params.email });
      }
    }
    if (params.modal === "true") {
      setShow(true);
    }
    if (params.firstClicked) {
      if (Array.isArray(params.firstClicked)) {
        props.setFirstClick({ firstClicked: atob(params.firstClicked[0]) });
      } else {
        props.setFirstClick({ firstClicked: atob(params.firstClicked) });
      }
    }
  }, []);
  return (
    <div className="personality" style={{ background: "white" }}>
      <div
        className="personality"
        style={{ background: "#FEF7ED", minHeight: "10vh", paddingTop: 84 }}
      >
        <img src="/img/personality/discount17.png" style={{ width: "80%" }} />
        <p
          className="personality__text"
          style={{ width: "60%", marginBottom: 10, marginTop: 15 }}
        >
          Nobody has it but<span style={{ color: "#4380FF" }}> you do</span>
        </p>
        <img src="/img/personality/discount80.png" style={{ width: "80%" }} />
        <div className="modal-line" style={{ paddingTop: 34 }}>
          <img
            src="/img/personality/personality20.png"
            style={{ width: 20, marginRight: 15 }}
          />
          <p className="modal-line__text">Tame your willpower</p>
        </div>
        <div className="modal-line">
          <img
            src="/img/personality/personality20.png"
            style={{ width: 20, marginRight: 15 }}
          />
          <p className="modal-line__text">
            Achieve goals by practicing positive habits step by step
          </p>
        </div>
        <div className="modal-line">
          <img
            src="/img/personality/personality20.png"
            style={{ width: 20, marginRight: 15 }}
          />
          <p className="modal-line__text">
            Build awesome new habits and get rid of the bad ones
          </p>
        </div>
        <div
          style={{
            width: "100%",
            textAlign: "center",
            padding: "16px 25px 30px 25px",
          }}
        >
          <button
            className="modal__button"
            onClick={() => {
              setShow(true);
            }}
          >
            Get My Discount
          </button>
        </div>
      </div>
      <p
        className="personality__text"
        style={{ width: "70%", marginBottom: 30, marginTop: 70 }}
      >
        Practice art of <span style={{ color: "#4380FF" }}> small steps</span>
      </p>
      <img src="/img/personality/discount15.png" style={{ width: "90%" }} />
      <p className="personality__text3" style={{ marginBottom: 40 }}>
        No need to exhaust yourself to achieving more. Celebrate every success
        and build habits on your road to a happy life.
      </p>
      <img src="/img/personality/discount16.png" style={{ width: "90%" }} />
      <p className="personality__text3" style={{ marginTop: 20 }}>
        Achieve goals by managing habits. Let your dreams come true and{" "}
        <b>“I wish I could”</b> become a reality.
      </p>
      <p
        className="personality__text"
        style={{ width: "90%", marginBottom: 30, marginTop: 70 }}
      >
        What our <span style={{ color: "#4380FF" }}> clients say</span>
      </p>
      <p
        className="successed__text2"
        style={{ width: "80%", textAlign: "center" }}
      >
        <span style={{ color: "#4380FF" }}>92% </span> of our clients built
        habits of successful life by following our custom habit forming plan!
      </p>
      <Slider />
      <p
        className="personality__text"
        style={{ width: "90%", marginBottom: 43, marginTop: 70 }}
      >
        Be the best version of{" "}
        <span style={{ color: "#4380FF" }}>yourself</span>
      </p>
      <div className="personality__version" style={{ marginBottom: 30 }}>
        <img src="/img/personality/discount5.png" style={{ width: 120 }} />
        <p className="version__text1">Today</p>
        <img src="/img/personality/discount7.png" style={{ width: 15 }} />
        <img src="/img/personality/discount6.png" style={{ width: 120 }} />
        <p className="version__text1">In 28 days</p>
        <p className="version__text2">
          You will improve health, life satisfaction, work performance and
          relationships with others
        </p>
        <button
          className="version__button"
          onClick={() => {
            setShow(true);
          }}
        >
          Get My Discount
        </button>
      </div>
      {/* <p
        className="personality__text"
        style={{ width: "90%", marginBottom: 30, marginTop: 70 }}
      >
        100% money-back{" "}
        <span style={{ color: "#4380FF" }}>
          guarantee
          <img
            src="/img/personality/personalityicon.png"
            style={{ marginLeft: 5, height: 33, width: 33 }}
          />
        </span>
      </p>
      <p className="personality__text2" style={{ marginBottom: 20 }}>
        If you feel that your plan won’t work for you, we guarantee to give you
        money back in <span style={{ color: "#4380FF" }}>14 days</span>.<br />
        <br /> But you know, there’s no way it will dissapoint you ;){" "}
      </p> */}

      <Footer />
      <MyVerticallyCenteredModal
        show={show}
        onHide={() => {
          handleClose();
          props.discount();
        }}
        price={price}
        link={`/discount3?email=${props.user.email}&firstClicked=${btoa(
          props.user.firstClicked
        )}&utm_source=${props.user.utm_source}`}
        successRedirect={"/appoffer"}
      />

      <MyVerticallyCenteredModal2
        show={show2}
        onHide={() => {
          handleClose();
          setShow2(true);
        }}
        setPrice={() => {
          setPrice(price + 999);
          setPromo(true);
        }}
      />
    </div>
  );
}

export default connect(
  (state) => ({ questions: state.questions, user: state.user }),
  (dispatch) => ({
    newGame: () => {
      dispatch({ type: "TEST" });
    },
    discount: () => {
      dispatch({ type: "DISCOUNT" });
    },
    setEmail: (payload) => {
      dispatch({ type: "SET_EMAIL", payload });
    },
    setFirstClick: (payload) => {
      dispatch({ type: "SET_FIRST_CLICK", payload });
    },
  })
)(withRouter(Personality));
