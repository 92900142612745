import React from "react";
import Button from "../../../../components/common/Button";
import Input from "../../../../components/common/Input";

import "./styles.scss";

const AuthorizeForm = ({
  isValidEmail,
  formValue,
  setFormValue,
  handleKeyboardSubmitEmail,
  onSubmitEmail,
}) => {
  return (
    <div className="authorize-form">
      <p className="authorize-form__description">
        Enter Your email which you
        <br />
        used on this website
      </p>
      <Input
        width={335}
        isValid={isValidEmail}
        formValue={formValue}
        setFormValue={setFormValue}
        placeholder="email@gmail.com"
        handleKeyboardSubmitEmail={handleKeyboardSubmitEmail}
      />
      <Button
        width={335}
        label="Continue"
        onClick={onSubmitEmail}
        isDisabled={!isValidEmail}
      />
      <a
        href="https://mail.google.com/mail/u/0/?fs=1&to=support@habit-box.me&tf=cm"
        className="authorize-form__link"
        target="_blank"
        rel="noreferrer"
      >
        Have you forgotten email?
      </a>
    </div>
  );
};

export default AuthorizeForm;
