import './QuestionBox.scss'
import React from 'react'

function QuestionBox(props){
    return (
        <div className="questionbox">
            {(props.title)?(<p className="questionbox__header">{props.title}</p>):(<></>)}
            {(props.text)?(<p className="questionbox__text">{props.text}</p>):(<></>)}            
        </div>
    )
}
export default QuestionBox