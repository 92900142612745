import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { amp, ampProps, ampSet } from '../../utils/amplitude'
import selectorSender from './SelectorSender'
import { trackPurchase } from './trackPurchase'
import './Upsale.scss'


function Upsale3(props) {
    const [successRedirect, setSuccessRedirect] = useState(false)
    const [price, setPrice] = useState(1999)
    const [disabled, setDisabled] = useState(false)
    const [selector,setSelector] = useState([true,false,false])
    const setIndexSelector = (index) =>{
        let newSelector = [false,false,false]
        newSelector[index] = true
        if(index === 0){
            setPrice(1999)
        }else if(index === 1){
            setPrice(1599)
        }else if(index === 2){
            setPrice(1599)
        }
        setSelector(newSelector)
    }
    useEffect(()=>{
        console.log(selector)
    },[selector])
    useEffect(()=>{
        console.log(price)
    },[price])

    useEffect(() => {
        ampSet('upsale_test','test')
        amp('upsell_shown_selector')
    }, [])
    const upgradeOrder = () => {
        ampSet('upsale_selector', 'upgraded')
        setDisabled(!disabled)
        console.log(props.user)
        if (props.user.cardToken && !props.user.upsale3) {
            fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/card/${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? 'test' : 'live'}/recure`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'auth': (process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_CARD_TEST_MERCHANT : process.env.REACT_APP_CARD_LIVE_MERCHANT
                },
                body: JSON.stringify({ amount: price, email: props.user.email || 'emailnotentered@gmail.com', token: props.user.cardToken })

            }).then((res) => {
                selectorSender(selector,props.user.email,()=>{
                    trackPurchase(price, 'purchase_selector')
                    props.setUpsale()
                    props.setBillPrice({ price: props.user.price + price })
                    setSuccessRedirect(true)
                    setDisabled(!disabled)
                })
            })
        } else if (props.user.paypalToken && !props.user.upsale3) {
            fetch(`${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_TEST_SERVER : process.env.REACT_APP_LIVE_SERVER}/api/paypal/${(process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? 'test' : 'live'}/recure`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'auth': (process.env.REACT_APP_TEST_DOMEN === window.location.origin) ? process.env.REACT_APP_PAYPAL_TEST_MERCHANT : process.env.REACT_APP_PAYPAL_LIVE_MERCHANT
                },
                body: JSON.stringify({ amount: price, email: props.user.email || 'emailnotentered@gmail.com', token: props.user.paypalToken })

            }).then((res) => {
                selectorSender(selector,props.user.email,()=>{
                    trackPurchase(price, 'purchase_selector')
                    props.setUpsale()
                    props.setBillPrice({ price: props.user.price + price })
                    setSuccessRedirect(true)
                    setDisabled(!disabled)
                })
            })
        } else {
            setSuccessRedirect(true)
            setDisabled(!disabled)
        }
    }
    if (successRedirect) return <Redirect push to={{ pathname: '/success' }} />
    return (
        <div className="upsale__screen1" style={{ background: ' #FFFCF8' ,minHeight:'100vh'}}>
            <img src='/img/upsale/upsale4.png' style={{width:120,position:'absolute',left:0,marginTop:35}}/>
            <div className="upsale__header">
                <p className="header__text">Additional  Discount<br />Applied just for you!</p>
            </div>
            <div className="upsale__main">
            <div className={(selector[0]?"main__block":"main__block main__block--unactive")} onClick={()=>setIndexSelector(0)}>
                <img className="block__check" src={`/img/upsale/${selector[0]?"":"un"}active.png`} />
                    <img className="block1" src="/img/upsale/upsale1.png" />
                    <div className="block2">
                        <p className="block2__header">100+ Days Challenge & CheckLists </p>
                        <span className="block2__text1">$19.99&nbsp;&nbsp;</span>
                        <strike className="block2__text2">$49.99</strike>
                        <span className="block2__text3">50% OFF</span>
                    </div>
                </div>
                <div className={(selector[1]?"main__block":"main__block main__block--unactive")} onClick={()=>setIndexSelector(1)}>
                <img className="block__check" src={`/img/upsale/${selector[1]?"":"un"}active.png`} />
                    <img className="block1" src="/img/upsale/upsale2.png" />
                    <div className="block2">
                        <p className="block2__header">Add 100+ Days Challenge</p>
                        <span className="block2__text1">$15.99&nbsp;&nbsp;</span>
                        <strike className="block2__text2">$24.99</strike>
                        <span className="block2__text3">35% OFF</span>
                    </div>
                </div>
                <div className={(selector[2]?"main__block":"main__block main__block--unactive")} onClick={()=>setIndexSelector(2)}>
                    <img className="block__check" src={`/img/upsale/${selector[2]?"":"un"}active.png`} />
                    <img className="block1" src="/img/upsale/upsale3.png" />
                    <div className="block2">
                        <p className="block2__header">10 Checklists to increase your productivity</p>
                        <span className="block2__text1">$15.99&nbsp;&nbsp;</span>
                        <strike className="block2__text2">$24.99</strike>
                        <span className="block2__text3">35% OFF</span>
                    </div>
                </div>
            </div>
            <button className="upsale3__button" style={(disabled)?{pointerEvents:'none',opacity:0.5}:{}} onClick={()=>upgradeOrder()}>Add to my plan</button>
            <Link push to="/success" onClick={()=>ampSet('upsale_selecotr','declined')}className="undertext" >No, thank you</Link>
        </div>
    )
}
export default connect(state => ({ user: state.user }), dispatch => ({
    setEmail: (payload) => {
        dispatch({ type: 'SET_EMAIL', payload })
    },
    setReload: () => {
        dispatch({ type: 'SET_RELOAD' })
    },
    setUpsale: () => {
        dispatch({ type: 'SET_UPSALE_3' })
    },
    setBillPrice: (payload) => {
        dispatch({ type: 'SET_PAID_PRICE', payload })
    }
}))(withRouter(Upsale3))
