import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { ampProps } from '../../../utils/amplitude'
import { ConfirmButton, Header, QuestionBox } from '../../exporter'
import './DealComponent.scss'
function DealComponent(props) {
    const answer = () => {
        // ampProps("question_answered", { question: props.match.params.id })
    }
    return (
        <>
            <Header back={true} question={`${props.match.params.id}/${props.questions.length}`} progress={parseInt(props.match.params.id) / props.questions.length * 100} />
            <div className="plan__block">
                <img className="block__img" src='/img/utils/plan.png' />
                <p className="block__text1">What is the first step towards dealing with habits? Making a plan!</p>
                <p className="block__text2">And you know what? We are already creating one for you.</p>
                <ConfirmButton submitLink={() => answer()} text={props.questions[parseInt(props.match.params.id - 1)].skip} active={true} button={props.questions[parseInt(props.match.params.id - 1)].buttonText} link={`/question/${parseInt(props.match.params.id) + 1}`} />
            </div>
        </>
    )

}


export default connect(state => ({ questions: state.questions }), dispatch => ({
    newGame: () => {
        dispatch({ type: 'TEST' })
    }
}))(withRouter(DealComponent))