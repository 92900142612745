import { useState } from "react"

let userState = {
    firstClicked:'',
    email: '',
    good:1,
    bad:1,
    goal:1,
    firstEmail:false,
    upsale1:false,
    upsale2:false,
    upsale3:false,
    successEmail:false,
    cardToken:'',
    paypalToken:'',
    price:0,
    utm_source:''
}
export default function userReducer(state = userState, action) {
    switch (action.type) {
        case "SET_EMAIL": {
            return { ...state, email: action.payload.email }
        }
        case "SET_BILL": {
            return { ...state,billed:true }
        }
        case "SET_FIRST_CLICK":{
            return { ...state, firstClicked: action.payload.firstClicked }
        }
        case "SET_GOOD":{
            return { ...state, good: action.payload.good }
        }
        case "SET_BAD":{
            return { ...state, bad: action.payload.bad }
        }
        case "SET_GOAL":{
            return { ...state, goal: action.payload.goal }
        }
        case "SET_FIRST_EMAIL":{
            return { ...state, firstEmail: true }
        }
        case "SET_SUCCESS_EMAIL":{
            return { ...state, successEmail:true }
        }
        case "SET_UPSALE_1":{
            return { ...state, upsale1:true }
        }
        case "SET_UPSALE_2":{
            return { ...state, upsale2:true }
        }
        case "SET_UPSALE_3":{
            return { ...state, upsale3:true }
        }
        case "SET_CARD_TOKEN":{
            return { ...state, cardToken:action.payload.token }
        }
        case "SET_PAYPAL_TOKEN":{
            return { ...state, paypalToken:action.payload.token }
        }
        case "SET_PAID_PRICE":{
            console.log(action.payload)
            return { ...state, price:action.payload.price }
        }
        case "SET_UTM_SOURCE":{
            return { ...state, utm_source:action.payload.utm_source }
        }
        default: {
            return { ...state }
        }
    }
}